<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        <h2>{{ $t('people') }}</h2>
      </div>
      <v-card tile flat>
        <v-btn plain :to="{ name: 'person-create' }">
          {{ $t('create') }}
        </v-btn>
      </v-card>
    </div>

    <div class="d-flex justify-start">
      <v-card tile flat :width="$vuetify.breakpoint.mdAndDown ? '100%' : '50%'">
        <v-form @submit.prevent="filter">
          <v-text-field
            class="my-3"
            v-model="filterTerm"
            :label="$t('filter')"
            clearable
            clear-icon="mdi-close mdi-sm"
            color="default"
            hide-details="true"
            full-width
            dense
            solo
            @click:clear="clear"
          >
          </v-text-field>
        </v-form>
      </v-card>
    </div>

    <v-data-table
      v-model="selectedRows"
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="serverItemsLength"
      class="elevation-1"
      item-key="id"
      :loading="isLoading"
      loading-text="Loading...."
      @click:row="view"
    >
      <template v-slot:item.id="{ item }">
        <div class="text-no-wrap">{{ item.shortId }}</div>
        "
      </template>

      <template v-slot:item.image="{ item }">
        <div class="text-center image-container py-1" v-if="item.keyImageId">
          <media-thumbnail
            :value="{ id: item.keyImageId }"
            :height="100"
            max-height="100px"
            max-width="125px"
          />
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div class="text-left">
          {{ item.description | truncate(150, '...') }}
        </div>
      </template>

      <template v-slot:item.collections="{ item }">
        <div
          class="text-left text-no-wrap "
          v-for="c in item.collections.filter(
            x => !x.isDeleted & !x.isArchived
          )"
          :key="c.id"
          :to="{ name: 'collection-detail', params: { collectionId: c.id } }"
        >
          {{ c.collectionShortId }} - {{ c.collectionNumber }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right text-no-wrap">
          {{ item.numberOfMedia }}
          <v-icon small class="mr-2" :title="$t('media')"
            >mdi-folder-multiple-image</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mediaThumbnail from '@/components/media/media-image'

export default {
  components: { mediaThumbnail },
  data() {
    return {
      advancedFilters: [],
      showFilters: false,
      filterTerm: '',
      footerProps: {
        showItemsPerPage: false
      },
      selectedRows: [],
      isLoading: true,
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['createdDateTime'],
        sortDesc: [true]
      },
      searchOptions: {}
    }
  },
  watch: {
    tableOptions: {
      handler(val) {
        this.searchOptions = {
          ...this.searchOptions,
          ...{
            page: val.page,
            orderBy: val.sortBy[0],
            isAscending: !val.sortDesc[0]
          }
        }
        this.filter()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('people', {
      items: 'results',
      meta: 'meta',
      term: 'term'
    }),
    headers() {
      return [
        { text: '', value: 'image', sortable: false },
        { text: this.$t('id'), value: 'shortId', width: '75px' },
        { text: this.$t('name'), value: 'name' },
        // { text: this.$t('description'), value: 'description' },
        { text: this.$t('collections'), value: 'collections', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    paging() {
      if (this.meta) {
        return this.meta.paging
      }
      return {}
    },
    serverItemsLength() {
      return this.meta?.paging?.totalItems
    }
  },
  async mounted() {
    if (this.$route.query.term) {
      this.filterTerm = this.$route.query.term
      await this.filter()
    } else {
      await this.all({})
      this.filterTerm = this.term
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions('people', ['all', 'search', 'setTerm']),
    async clear() {
      this.selectedRows = []

      if (this.term) {
        const options = {}
        await this.all(options)
        this.setTerm({ term: this.filterTerm })
      }
    },
    async filter() {
      this.searchOptions.term = this.filterTerm
      this.selectedRows = []

      this.setTerm(this.searchOptions)

      if (this.searchOptions.term) {
        await this.search(this.searchOptions)
      } else {
        await this.all(this.searchOptions)
      }
    },
    view(item) {
      this.$router.push({
        name: 'person-detail',
        params: { personId: item.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr:hover {
  cursor: pointer;
}

::v-deep .v-data-footer__select {
  display: none;
}
</style>
