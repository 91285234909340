var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('people')))])]),_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-btn',{attrs:{"plain":"","to":{ name: 'person-create' }}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-start"},[_c('v-card',{attrs:{"tile":"","flat":"","width":_vm.$vuetify.breakpoint.mdAndDown ? '100%' : '50%'}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.filter($event)}}},[_c('v-text-field',{staticClass:"my-3",attrs:{"label":_vm.$t('filter'),"clearable":"","clear-icon":"mdi-close mdi-sm","color":"default","hide-details":"true","full-width":"","dense":"","solo":""},on:{"click:clear":_vm.clear},model:{value:(_vm.filterTerm),callback:function ($$v) {_vm.filterTerm=$$v},expression:"filterTerm"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.tableOptions,"server-items-length":_vm.serverItemsLength,"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading...."},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.shortId))]),_vm._v(" \" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.keyImageId)?_c('div',{staticClass:"text-center image-container py-1"},[_c('media-thumbnail',{attrs:{"value":{ id: item.keyImageId },"height":100,"max-height":"100px","max-width":"125px"}})],1):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.description,150, '...'))+" ")])]}},{key:"item.collections",fn:function(ref){
var item = ref.item;
return _vm._l((item.collections.filter(
          function (x) { return !x.isDeleted & !x.isArchived; }
        )),function(c){return _c('div',{key:c.id,staticClass:"text-left text-no-wrap ",attrs:{"to":{ name: 'collection-detail', params: { collectionId: c.id } }}},[_vm._v(" "+_vm._s(c.collectionShortId)+" - "+_vm._s(c.collectionNumber)+" ")])})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-right text-no-wrap"},[_vm._v(" "+_vm._s(item.numberOfMedia)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('media')}},[_vm._v("mdi-folder-multiple-image")])],1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }